<template>
    <div class="delay-account">
        <!-- 已到账 -->
        <ul class="account-list" v-if="countType == 0">
            <li class="account-item" v-for="(item,index) in unAccountlist" :key="index">
                <div class="account-item-info">
                    <div  class="account-item-img">
                        <img :src="item.img ? item.img : 'https://kuaidiandian.oss-cn-shenzhen.aliyuncs.com/kuandianidna-images/user-fission/user_fission_tx.png'" alt="">
                    </div>
                    <div class="account-item-detail">
                        <p class="name">{{item.nickName}}</p>
                        <p>待到账金额 <span class="number">{{item.price}}</span><span class="yuan">元</span></p>
                    </div>
                </div>
                <div class="account-item-btn">
                    <button @click="openDetail(item)">查看</button>
                </div>
            </li>
        </ul>
        <!-- 待到账 -->
        <ul v-else class="account-list">
            <li  class="account-item" v-for="(item,index) in accountList" :key="index">
                <div class="account-item-detail">
                    <p class="name">拉新--{{item.nickName}}</p>
                    <p>{{item.time}}</p>
                </div>
                <div :class="['accounted-money',{'green-color':item.price < 0}]">+{{item.price}} 金币</div>
            </li>
        </ul>
        <!-- 底部加载 -->
        <div class="account-load-more" @click="loadMore()">{{loadTips}}</div>

        <!-- 详情弹框 -->
        <van-dialog v-model="progressShow" :show-confirm-button="false" :width="'80%'" :className="'no-hidden-comfirm'">
            <div class="detail-comfirm">
                <FriendStep 
                :itemStep="itemStep" 
                :isdialog='true'
                @closeComfirm="closeComfirm"
                @remind="remind" />
                <div class="comfirm-close-btn" @click="progressShow = false">
                    <span class="iconfont">&#xe6a3;</span>
                </div>
            </div>
        </van-dialog>

        <!-- 邀请弹出层 -->
        <van-dialog v-model="invitedShow" :show-confirm-button="false" :width="'80%'" :className="'no-hidden-comfirm'">
            
            <div class="invited-comfirm">
                <div class="comfirm-text">
                    <p>1、选择微信好友或微信群</p>
                    <p>2、在聊天窗口长按"粘贴"</p>
                </div>
                <div class="comfirm-guide-img">
                    <img src="https://kuaidiandian.oss-cn-shenzhen.aliyuncs.com/kuandianidna-images/user-fission/user_fission_paste_img.png" alt="">
                </div>
                <div  class="comfirm-paste-btn">
                    <button @click="toPaste()">去粘贴</button>
                </div>
                <div class="comfirm-close-btn" @click="invitedShow = false">
                    <span class="iconfont">&#xe6a3;</span>
                </div>
            </div>
        </van-dialog>
    </div>
</template>

<script>
export default {
    components: {
        FriendStep: () => import('@/views/landing/friendStep')
    },
    data() {
        return {
            unAccountlist:[],           // 待到账数据
            accountList:[],             // 已到账数据
            loadTips: '点击加载更多',    // 加载文本
            progressShow: false,        // 详情弹框
            itemStep: {},               // 当前查看的数据
            countType: 0,               // 0（待到账）/1（已到账）
            invalidTimer: null,         // 定时器
            invitedShow: false,         // 控制邀请弹框显隐
            params:{
                pageNum: 1,             // 第N页
                pageSize: 5             // 每页显示数量
            },
            listIsLoadFinsh: false,      // 是否请求列表完成
            detailIsLoadFinish: false,   // 是否请求详情完成
            isAndroid:true,             // 浏览器是否为安卓浏览器
            isIos:false,                // 浏览器是否为ios浏览器
        }
    },
    beforeDestroy(){
        clearInterval(this.invalidTimer)
    },
    mounted() {
        this.countType = this.$route.query.type;
        let Navigator = navigator.userAgent;
        this.isAndroid = Navigator.match(/(Android);?[\s\/]+([\d.]+)?/) ? true : false;
        this.isIos = !!Navigator.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

        if(this.countType == 0){
            this.getWaitAccountMoney(0)
        }else {
            this.getAlreadAccountMoney(0)
        }

        // 定时函数
        this.interval();
    },
    methods: {
        /**
         * 获取待到账数据
         */
        getWaitAccountMoney(number){
            if(!this.listIsLoadFinsh){
                this.listIsLoadFinsh = true;
                this.params.pageNum += number;
                this.$api.waitAccount({
                    params: this.params,
                    headers:{
                        'Authorization': this.$route.query.token
                    }
                }).then(result=>{
                    this.listIsLoadFinsh = false;
                    var res = result.data;
                    if(res.code == 200){
                        this.unAccountlist = this.unAccountlist.concat(res.data.records);
                        if(this.unAccountlist.length == res.data.total){
                            this.loadTips = '已全部加载';
                        }else{
                            this.loadTips = '点击加载更多';
                        }
                    }else{
                        this.params.pageNum -= number;
                        this.$toast(res.msg)
                    }
                }).catch(err=>{
                    this.params.pageNum -= number;
                    this.listIsLoadFinsh = false;
                    this.$toast(err.message);
                })
            }
            
        },
        /**
         * 获取已到账数据
         */
        getAlreadAccountMoney(number){
            if(!this.listIsLoadFinsh){
                this.listIsLoadFinsh = true;
                this.params.pageNum += number;
                this.$api.alreadyAccount({
                    params: this.params,
                    headers:{
                        'Authorization': this.$route.query.token
                    }
                }).then(result=>{
                    this.listIsLoadFinsh = false;
                    var res = result.data;
                    if(res.code == 200){
                        this.accountList = this.accountList.concat(res.data.records);
                        if(this.accountList.length == res.data.total){
                            this.loadTips = '已全部加载'
                        }else{
                            this.loadTips = '点击加载更多'
                        }
                    }else{
                        this.params.pageNum -= number;
                        this.$toast(res.msg)
                    }
                }).catch(err=>{
                    this.params.pageNum -= number;
                    this.listIsLoadFinsh = false;
                    this.$toast(err.message);
                })
            }
            
        },
        /**
         * 加载更多函数
         */
        loadMore(){
            if(this.loadTips != '已全部加载'){
                this.loadTips = '正在加载...';
                if(this.countType == 0){
                    this.getWaitAccountMoney(1);
                }else {
                    this.getAlreadAccountMoney(1);
                }
            }else{
                this.$toast('已全部加载')
            }
        },
        /**
         * 查看详情函数
         */
        openDetail(item){
            if(!this.detailIsLoadFinish){
                this.detailIsLoadFinish = true;
                this.$api.waitAccountDetail({
                    uid: item.uid,
                    headers:{
                        'Authorization': this.$route.query.token
                    }
                }).then(result=>{
                    var res = result.data;
                    this.detailIsLoadFinish = false;
                    if(res.code == 200){
                        this.progressShow = true;
                        this.itemStep = res.data;
                    }else{
                        this.$toast(res.msg)
                    }

                }).catch(err=>{
                    this.detailIsLoadFinish = false;
                    this.$toast(err.message);
                })
            }
            
        },
        /**
         * 提醒TA函数
         */
        remind(){
            this.invitedShow = true;
        },
        /**
         * 关闭详情弹框
         */
        closeComfirm(bool){
            this.progressShow = bool;
        },
        /**
         * 随机生成指定空格，用来加入粘贴内容中
         * 手机上如果是相同的粘贴内容，第二次及以后不会出现在键盘上方
         */
        random(a,b){
            var randomNum = Math.round(Math.random() * (b - a) + a);
            return randomNum;
        },
        /**
         * 粘贴函数
         */
        toPaste(){
            var value = 'Hi，帮个忙，登录凯迪网，咱俩都有钱拿！';
            var url = location.origin + `/?openCode=${Math.random().toString().slice(12)}`
            var input = document.createElement('input');
            input.setAttribute('readonly', 'readonly');     // 防止调起键盘面板
            input.setAttribute('value', value + url);
            document.body.appendChild(input);
            input.select();
            // 如果ios不兼容，可以加这行代码试试    input.setSelectionRange(0, 9999);
            if (document.execCommand('copy')) {
                document.execCommand('copy');
            }
            document.body.removeChild(input);
            
            // 收起粘贴弹窗
            this.invitedShow = false;

            // 判断是安卓或者ios，唤醒唤醒微信面板，需要跟移动端同学对接
            if(this.isAndroid){
                window.app.pasteShareMsg('');
            }else if(this.isIos){
                window.webkit.messageHandlers.pasteShareMsg.postMessage('');
            }
        },
        /**
         * 某一天的最后毫秒数
         * day: 第几天
         */
        dateLastMinute(day){
            return (new Date(new Date().toLocaleDateString()).getTime() + 24 * day * 60 * 60 * 1000 - 1)
        },
        /**
         * 计算按剩余时间
         * second   Number   时间,单位为秒
         */
        countDown(second) {
            var invalidTime = ''
            var d = parseInt(second / 60 / 60 / 24); // 天
            var h = parseInt(second / 60 / 60 % 24).toString().padStart(2, '0'); //时
            var m = parseInt(second / 60 % 60).toString().padStart(2, '0');; // 分
            var s = parseInt(second % 60).toString().padStart(2, '0');; // 当前的秒
            if(d >= 1){
                invalidTime = d + '日' + h + ':' + m + ':' + s;
            }else{
                invalidTime = h + ':' + m + ':' + s;
            }
            return invalidTime
        },
        /**
         * 定时器，实时计算任务领取奖励剩余时间
         */
        interval(){
            this.invalidTimer = setInterval(() => {
                var key = 'rewardTime';
                 if(this.itemStep.proStatus == 0){
                    key = 'time'
                }
                this.itemStep[key] -= 1;
                if(this.itemStep[key] < 0){
                    if(this.itemStep.price != 0){
                        this.itemStep.price = -1;
                    }
                }else{
                    var invalidTime = this.countDown(this.itemStep[key]);
                    this.$set(this.itemStep, 'invalidTime', invalidTime);
                }
            }, 1000);
        },
    },
}
</script>

<style lang="less">
    .delay-account{
        min-height: 100vh;
        font-size: 14px;
        background: #f8f8f8;
        .account-list{
            padding: 0 15px;
            background: white;
            .account-item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                padding: 10px 0;
                &::after{
                    content: '';
                    position: absolute;
                    left: -50%;
                    bottom: 0;
                    width: 200%;
                    height: 1px;
                    background: #f0f0f0;
                    transform: scale(0.5);
                }
            }
            .account-item-info{
                display: flex;
                align-items: center;
                .account-item-img{
                    overflow: hidden;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    img{
                        display: block;
                        width: 100%;
                    }
                }
            }
            .account-item-detail{
                margin-left: 10px;
                font-size: 15px;
                p{
                    height: 28px;
                    line-height: 30px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    &:nth-child(2){
                        font-size: 13px;
                        color: #999999;
                    }
                    &:nth-child(1){
                        font-weight: 500;
                    }
                    .number{
                        font-weight: 500;
                        color: var(--Color);
                        font-size: 15px;
                        font-family: PingFangSC-Medium, PingFang SC;
                    }
                    .yuan{
                        color: var(--Color);
                    }
                }
                .name{
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 34px;
                }
            }
            .accounted-money{
                font-weight: 500;
                color: var(--Color);
                font-size: 15px;
            }
            .green-color{
                color: #58C95F;
            }
            .account-item-btn{
                button{
                    padding: 0 15px;
                    border: none;
                    height: 25px;
                    background: linear-gradient(180deg, #FD917B 0%, #FF1F14 100%);
                    border-radius: 14px;
                    color: white;

                }
            }
        }
        .account-load-more{
            padding: 20px 0;
            text-align: center;
            font-size: 12px;
            color: #ccc;
        }
        .no-hidden-comfirm{
            overflow: initial;
            background: #ffece5;
            padding: 15px 0;
        }
        .invited-comfirm,.detail-comfirm{
            position: relative;
            padding: 40px 20px 10px;
            .comfirm-close-btn{
                position: absolute;
                bottom: -90px;
                left: 50%;
                z-index: 2;
                width: 30px;
                height: 30px;
                border: 2px solid #dedede;
                border-radius: 50%;
                line-height: 30px;
                color: #dedede;
                text-align: center;
                transform: translate(-50%, 0);
                .iconfont{
                    font-size: 12px;
                }
            }
            .comfirm-text{
                padding-left: 30px;
                p{
                    margin-bottom: 22px;
                }
            }
            .comfirm-guide-img{
                box-sizing: border-box;
                margin: 0 0 15px 0;
                border: 1px dashed #999;
                img{
                    display: block;
                    width: 100%;
                }
            }
            .comfirm-paste-btn{
                text-align: center;
                button{
                    width: 90%;
                    padding: 14px 0;
                    border: none;
                    outline: none;
                    background: linear-gradient(0deg, #FA3638, #FB706B, #FB706B);
                    border-radius: 50px;
                    color: white;
                    font-size: 18px;
                    font-weight: 500;
                }
            }
        }
        .detail-comfirm{
            padding: 0;
            .comfirm-close-btn{
                bottom: -90px;
            }
        }
        .van-overlay{
            background: rgba(0,0,0,0.5);
        }
    }
    
</style>

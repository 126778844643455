<template>
    <div class="invited-page">
        <div class="invited-content" v-if="!isOutTime">
            <img src="https://cdn.9kd.com/kdnet/user-fission/user_fission_invited_page_bg.png" alt="">
            <div class="invited-btn" id="downloadButton" @click="openInstall()">
                <img src="https://cdn.9kd.com/kdnet/user-fission/user_fission_invited_btn.png" alt="">
            </div>
        </div>
        <div v-else style="text-align: center; padding-top: 100px; color: white; font-size: 28px;">
            {{ isOutTime == 1 ? '活动已结束' : '活动未开始'}}
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            urlParams:{},
            isOutTime: 0,
            ID:'',
            startTime: '',
            endTime: '',
            androidUrl: 'https://cdn.9kd.com/app_package/oam/kdnet_kdnet_product_6.9.0_build_261.apk'
        }
    },
    created(){
        // 获取时间
        this.inviteTime();

        this.downloadApp();
        
    },
    mounted() {
        let isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
        if(!isIOS){
            this.ID = location.origin.indexOf('m.9kd') >= 0 ? "usr1kd6h08jeersh" : "usr1hm543sq6gh9d"
        }else{
            this.ID = location.origin.indexOf('m.9kd') >= 0 ? "usr1kd6h08jeersh" : "usr1hm543sq6gh9d"
        }

        // url问号后面的参数
        this.urlParams = ULink.getUriDecodeParams()

        // 微信二次分享，全局混入函数
        this.setWeChatShare({
            url: window.location.href,
            title: '帮我点一下，咱俩都有钱，当天就能提现！',
            description: '',
            friendImg: this.$baseInfo.LOGO_IMG,
            momentsImg: this.$baseInfo.MOMENTS_LOGO_IMG
        });
    },
    methods: {
        inviteTime() {
          this.$api.inviteTime().then(result=>{
            let res = result.data;
            if(res.code == 200 && res.data) {
              let startTime = res.data.startTimeStr.replaceAll('.','-');;
              let endTime = res.data.endTimeStr.replaceAll('.','-');
              // 超过2021年7月31日24点 不能打开1627747199000
              if(new Date().getTime() > new Date(endTime + ' 23:59:59').getTime()){
                  this.isOutTime = 1;
              } 
              if(new Date().getTime() < new Date(startTime + ' 00:00:00').getTime()) {
                  this.isOutTime = 2;
              }
            }
          }).catch(err=>{})
        },
        async downloadApp() {
            let isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
            if (!isIOS) {
                let { data: res } = await this.$api.getUpdateInfo();
                if(res.code == 200){
                    this.androidUrl = res.data.url;
                }
                this.openInstall()
            }else {
                this.openInstall()
            }
        },
        openInstall(){
            let isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
            let self = this;
            ULink([
                {
                    id: this.ID,          // 后台生成的裂变活动LinkID
                    data: this.urlParams,           // 传递的自定义动态参数
                    selector: "#downloadButton",    // 按钮的名称
                    useOpenInBrowerTips: "default",
                    timeout: 2000,
                    // lazy: false,
                    auto: isIOS ? true : false,
                    proxyOpenDownload: function (defaultAction, LinkInstance){
                        if (LinkInstance.solution.type === "scheme"){
                            // qq或者微信环境特殊处理下
                            if (ULink.isWechat || ULink.isQQ) {
                                // 在qq或者微信环境执行内置逻辑，具体内置逻辑为:当设置了useOpenInBrowerTips字段时，qq&&微信&&scheme时，启用蒙层提示去浏览器打开
                                defaultAction();
                            }else{
                                window.location.href = self.androidUrl || LinkInstance.solution.downloadUrl;
                            }
                        }else if(LinkInstance.solution.type === "universalLink"){
                            // universalLink 唤起应当由服务端提供一个带重定向到appstore的universallink地址。因此，此处不应写逻辑，友盟已于6月2日上线universalLink生成及重定向功能。
                        }
                    },
                },
            ]);
        }
    },
}
</script>

<style lang="less">
    .invited-page{
        min-height: 100vh;
        background: #fe9345;
        img{
            display: block;
            width: 100%;
        }
        .invited-content{
            position: relative;
        }
        .invited-btn{
            position: absolute;
            bottom: 20px;
            left: 0;
            animation: breathing .4s ease infinite alternate;
            img{
                width: 85%;
                margin: 0 auto;
            }
        }
    }
</style>
<style>
@keyframes breathing
{
    from {
        transform: scale(0.9);
    }
    to {
        transform: scale(1);
    }
}
</style>

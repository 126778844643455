<template>
    <div class="user-fission" v-show="timeoutLoad">
        <div class="main">
            <div class="user-title">
                <img src="https://cdn.9kd.com/kdnet/user-fission/user_fission_title.png" alt="">
            </div>
            <div class="user-contet">
                <img class="user-contet-bg" src="https://cdn.9kd.com/kdnet/user-fission/user_fission_envelope.png" alt="">
                <div class="user-content-tips">每邀请 1 位新用户使用</div>
                <div class="user-content-money">
                    <img src="https://cdn.9kd.com/kdnet/user-fission/user_fission_seven_yuan.png" alt="">
                </div>
                <div class="user-content-get-text">
                    你可获得
                </div>
                <div class="user-content-time">活动有效期：{{startTime}}至{{endTime}}</div>
                <div class="user-content-invite-btn" @click="showInvited('邀请')" ref="invitedButton">
                    <p>
                        <img src="https://cdn.9kd.com/kdnet/user-fission/user_fisson_wx_jb.png" alt="">
                        <span class="user-content-invite-ljyq" data-text="立即邀请">立即邀请</span>
                    </p>
                </div>
                <div class="user-content-type">
                    <p @click="showInvited('邀请')"><span class="iconfont">&#xe668;</span> 分享到微信群</p>
                    <p @click="showShare()"><span class="iconfont">&#xe669;</span> 面对面分享</p>
                </div>
                
            </div>
        </div>
        
        <div class="user-uninvited-content">
            <!-- 好友进度 -->
            <div class="user-uninvited-bg">
                <div class="invited-same-title">收益情况</div>
                <div class="user-account">
                    <div class="user-account-btn" @click="ToAccount(0,accountData.wait)">
                        <p class="user-account-title">待到账</p>
                        <p class="user-account-money"><span>{{accountData.wait.toFixed(2)}}</span>元</p>
                    </div>
                    <div class="user-account-btn" @click="ToAccount(1,accountData.already)">
                        <p class="user-account-title">已到账</p>
                        <p class="user-account-money"><span>{{accountData.already.toFixed(2)}}</span>元</p>
                    </div>
                </div>
            </div>

            <!-- 好友进度 -->
            <div class="user-uninvited-bg" v-if="friendList.length">
                <div class="invited-same-title">好友进度</div>
                <div class="user-friend-list">
                    <FriendStep v-for="(item,index) in friendList" :key="index" :itemStep="item" 
                    @remind="showInvited('提醒')"
                    @receivedReward="receivedReward" />
                    <div class="user-friend-load-more" @click="getFriendList(1,'concat')">{{loadText}}</div>
                </div>
            </div>
            <!-- 活动玩法 -->
            <div class="user-uninvited-bg">
                <div class="invited-same-title">领红包玩法</div>
                <div class="invited-playing-type">
                    <div class="invited-playing-step">
                        <div class="invited-playing-img">
                            <img src="https://cdn.9kd.com/kdnet/user-fission/user_fission_wx.png" alt="">
                        </div>
                        
                        <p>点击按钮<br>分享给好友</p>
                    </div>
                    <div class="invited-playing-arrow">
                        <img src="https://cdn.9kd.com/kdnet/user-fission/user_fission_arrow.png" alt="">
                    </div>
                    <div class="invited-playing-step">
                        <div class="invited-playing-img">
                            <img src="https://cdn.9kd.com/kdnet/user-fission/user_fission_lj.png" alt="">
                        </div>
                        <p>好友打开链接<br>下载安装</p>
                    </div>
                    <div class="invited-playing-arrow">
                        <img src="https://cdn.9kd.com/kdnet/user-fission/user_fission_arrow.png" alt="">
                    </div>
                    <div class="invited-playing-step">
                        <div class="invited-playing-img">
                            <img src="https://cdn.9kd.com/kdnet/user-fission/user_fission_hb.png" alt="">
                        </div>
                        <p>好友登录阅读<br>您获得红包</p>
                    </div>
                </div>
            </div>
            <!-- 如何获得7元 -->
            <div class="user-uninvited-bg">
                <div class="invited-same-title">如何获得7元</div>
                <div class="invited-playing-win">
                    <div class="invited-win-rule">
                        <div class="invited-rule-item">
                            <div class="invited-rule-left">
                                <div class="invited-rule-icon">
                                    <span class="iconfont">&#xe66b;</span>
                                </div>
                                <div class="invited-rule-text">
                                    <p class="invited-rule-t-big">好友首登并绑定手机号</p>
                                    <p class="invited-rule-t-small">越分享越幸运 每天可分享</p>
                                </div>
                            </div>
                            <div class="invited-rule-money">
                                <img src="https://cdn.9kd.com/kdnet/user-fission/user_fission_one_yuan.png" alt="">
                            </div>
                        </div>
                        <div class="invited-rule-item">
                            <div class="invited-rule-left">
                                <div class="invited-rule-icon">
                                    <span class="iconfont">&#xe667;</span>
                                </div>
                                <div class="invited-rule-text">
                                    <p class="invited-rule-t-big">好友首登次日</p>
                                    <p class="invited-rule-t-small">阅读1篇文章</p>
                                </div>
                            </div>
                            <div class="invited-rule-money">
                                <img src="https://cdn.9kd.com/kdnet/user-fission/user_fission_one_yuan.png" alt="">
                            </div>
                        </div>
                        <div class="invited-rule-item">
                            <div class="invited-rule-left">
                                <div class="invited-rule-icon">
                                    <span class="iconfont">&#xe665;</span>
                                </div>
                                <div class="invited-rule-text">
                                    <p class="invited-rule-t-big">好友首登7日</p>
                                    <p class="invited-rule-t-small">阅读1篇文章</p>
                                </div>
                            </div>
                            <div class="invited-rule-money">
                                <img src="https://cdn.9kd.com/kdnet/user-fission/user_fission_five_yuan.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="invited-explain">* 好友为您贡献奖励后，您需在10天内领完，过期奖励失效；奖励将以金币的形式发放，请前往“福利中心”进行提现</div>
                </div>
            </div>
            <!-- 邀请攻略 -->
            <div class="user-uninvited-bg">
                <div class="invited-same-title">邀请攻略</div>
                <div class="invited-strategy">
                    <div><p class="num">1</p> <p>邀请您的家人、朋友、同学、同事成功几率更大；</p></div>
                    <div><p class="num">2</p> <p>如果在微信分享是弹出"发生异常，无法分享"的提示，只需要将微信退出重新登录，就可以正常分享；</p></div>
                    <div><p class="num"></p> <p> 小提醒：邀请好友后，记得提醒他每天回来阅读内容哦。</p></div>
                </div>
            </div>
            
        </div>

        <!-- 活动规则 -->
        <div class="invited-fixed-rule" @click="ruleShow = true">活动规则</div>
        <!-- 底部固定按钮 -->
        <div v-show="bottomInvitedShow" class="invited-fixed-btn" @click="showInvited('邀请')">
            <p>
                <img src="https://cdn.9kd.com/kdnet/user-fission/user_fisson_wx_jb.png" alt="">
                <span class="user-content-invite-ljyq" data-text="立即邀请">立即邀请</span>
            </p>
        </div>

        <!-- 活动规则弹框 -->
        <van-dialog v-model="ruleShow" :show-confirm-button="false" :className="'no-hidden-comfirm'">
            
            <div class="invited-comfirm small-padding">
                <div class="invited-same-title">
                    活动规则
                </div>
                <div class="invited-strategy">
                    <div><p class="num">1</p> <p>活动有效期{{startTime}}至{{endTime}}，该有效期内您可以让好友通过您分享的链接下载并登录凯迪网APP开启任务；逾期后邀请成功将无法产生奖励</p></div>
                    <div><p class="num">2</p> <p>活动期内可邀请的好友数量无上限，但每自然日最多可邀请5人；当日到达上限后无法分享邀请页，新下载登录的好友将不被视为邀请成功</p></div>
                    <div><p class="num">3</p> <p>奖励获得说明：奖励共三档，分享给好友，让好友通过您分享的链接下载并登录凯迪网APP，您可获得1元的第一档奖励；登录后的连续第二日，好友在该天内打开APP并阅读1篇内容，即可获得1元的第二档奖励；登录后的连续第三天起到连续第七天，好友每天都在当天内打开APP并阅读1篇内容，即可在第七天的节点获得5元的第三档奖励</p></div>
                    <div><p class="num">4</p> <p>郑重说明：为了保证广大用户的收益不被影响，对于存在非正常邀请行为的用户，平台将取消其参加活动的资格，并扣除相应奖励不予奖励，严重者将进行封号处理</p></div>
                    <div><p class="num">5</p> <p>本活动解释权归凯迪网络信息技术（海南）有限公司所有</p></div>
                </div>
                <div class="comfirm-close-btn"  @click="ruleShow = false">
                    <span class="iconfont">&#xe6a3;</span>
                </div>
            </div>
        </van-dialog>

        <!-- 邀请弹出层 -->
        <van-dialog v-model="invitedShow" :show-confirm-button="false" :className="'no-hidden-comfirm'">
            
            <div class="invited-comfirm">
                <div class="comfirm-text">
                    <p>1、选择微信好友或微信群</p>
                    <p>2、在聊天窗口长按"粘贴"</p>
                </div>
                <div class="comfirm-guide-img">
                    <img src="https://cdn.9kd.com/kdnet/user-fission/user_fission_paste_img.png" alt="">
                </div>
                <div  class="comfirm-paste-btn">
                    <button @click="toPaste()">去粘贴</button>
                </div>
                <div class="comfirm-close-btn" @click="closeComfirm('invitedShow')">
                    <span class="iconfont">&#xe6a3;</span>
                </div>
            </div>
        </van-dialog>

        <!-- 面对面分享弹框 -->
        <van-dialog v-model="shareShow" :show-confirm-button="false" :className="'no-hidden-comfirm'">
            <div class="share-comfirm">
                <div class="share-comfirm-bg">
                    <img src="https://cdn.9kd.com/kdnet/user-fission/user_fission_share_erweima_bg.png" alt="">
                </div>
                <div class="comfirm-qrcode">
                    <img :src="qrCode" alt="">
                </div>
                <div class="share-comfirm-close-btn" @click="closeComfirm('shareShow')">
                    <span class="iconfont">&#xe6a3;</span>
                </div>
            </div>
        </van-dialog>

        <!-- 未登录弹框 -->
        <van-dialog v-model="loginShow" :show-confirm-button="false" :className="'no-hidden-comfirm'">
            <div>
              <div class="login-tips">您还没有登录哦</div>
              <div class="button-group">
                <div @click="loginShow = false" class="btn">取消</div>
                <div @click="toAppLogin" class="btn red">去登录</div>
              </div>
            </div>
        </van-dialog>
    </div>
</template>

<script>
export default {
    components: {
        FriendStep: () => import('@/views/landing/friendStep')
    },
    data() {
        return {    
            invitedShow: false,             // 控制邀请弹框分享显隐
            shareShow: false,               // 控制面对面分享弹框显隐
            bottomInvitedShow: false,       // 控制底部按钮显隐
            friendList:[],                  // 好友进度列表
            
            invalidTimer: null,         // 时间定时器
            personNumber: 0,            // 已邀请人数
            invitationType: '邀请',     // 邀请类型，用来判断粘贴内容
            isAndroid:true,             // 浏览器是否为安卓浏览器
            isIos:false,                // 浏览器是否为ios浏览器
            urlParams:{},
            friendParams:{
                pageNum: 1,             // 第N页
                pageSize: 5             // 每页显示数量
            },
            accountData:{
                already:0.00,           // 已到账
                wait:0.00               // 待到账
            },
            loadText:'加载更多',
            headersConfig:{},           // 携带token
            isloadFinsh:false,          // 是否请求完成
            qrCode:'',                  // 二维码图片
            invitedCode:'',             // 邀请码
            appKey:'',                  // 友盟appKey
            ruleShow: false,            // 活动规则弹框
            timeoutLoad: false,         // 图片加载完成再显示
            endTime: '2021.12.03',      // 活动开始时间
            startTime: '2021.12.08',    // 活动结束时间
            loginShow: false
        }
    },
    beforeDestroy(){
        // 退出移除滚动函数、定时器
        window.removeEventListener('scroll', this.scrollHandler);
        clearInterval(this.invalidTimer)
    },
    mounted() {
        // 主要给app调用， 
        window.updateToken = (tokken) => {
           // 接口header携带token
          this.urlParams.token = tokken;
          this.headersConfig = {
              headers:{
                  'Authorization': tokken
              }
          }
          // 待到账已到账
          this.getOldAllAccount();

          // 好友列表
          this.friendParams = {
              pageNum: 1,
              pageSize: 5
          },
          this.friendList = [];
          this.loadText = '加载更多';
          this.isloadFinsh = false;
          this.getFriendList(0,'concat');

          // 获取邀请码
          this.getInvitedCode();

          // 面对面分享二维码
          this.getQRcode();
        }
        let Navigator = navigator.userAgent;
        this.isAndroid = Navigator.match(/(Android);?[\s\/]+([\d.]+)?/) ? true : false;
        this.isIos = !!Navigator.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

        // url问号后面的参数
        if(location.search != ''){
            var ls = location.search.substr(1)
            var params = ls.split('&');
            for (let key of params) {
                let data = key.split('=');
                this.urlParams[data[0]] = data[1]
            }
        }

        // 接口header携带token
        this.headersConfig = {
            headers:{
                'Authorization': this.urlParams.token
            }
        }

        // 友盟appKey(android和ios不一样)
        if (this.isAndroid) {
            this.appKey = location.origin.indexOf('m.9kd') >= 0 ? '5ee9bad8570df372a7000085' : '60c81b4ca82b08615e50b750';
        } else {
            this.appKey = location.origin.indexOf('m.9kd') >= 0 ? '60fbd6df173f3b21b453f4ce' : '60fbd744ff4d74541c81decb';
        }

        //全部加载完成再显示
        this.$nextTick(()=>{
            this.timeoutLoad = true;
        })

        // 获取时间
        this.inviteTime();

        // 待到账已到账
        this.getOldAllAccount();

        if(this.urlParams.token){
          // 好友列表
          this.getFriendList(0,'concat');
        }
        
        // 获取邀请码
        this.getInvitedCode();

        // 面对面分享二维码
        this.getQRcode();

        // 滑动函数
        window.addEventListener('scroll', this.scrollHandler);

        //  定时器
        this.interval();
    },
    methods: {
        inviteTime() {
          this.$api.inviteTime().then(result=>{
            let res = result.data;
            if(res.code == 200 && res.data) {
              this.startTime = res.data.startTimeStr;
              this.endTime = res.data.endTimeStr;
            }
          }).catch(err=>{})
        },
        getOldAllAccount(){
            this.$api.oldUserAccount(this.headersConfig).then(result=>{
                var res = result.data;
                if(res.code == 200){
                    this.accountData = res.data;
                }
            }).catch(err=>{})
        },
        getFriendList(number,type){
            if(type != 'reset'){
                if(this.loadText == '已全部加载'){
                    this.$toast('已全部加载')
                    return
                }
            }
            
            if(!this.isloadFinsh){
                this.isloadFinsh = true;
                this.friendParams.pageNum += number;
                this.loadText = '正在加载...';
                this.$api.friendStepListData({
                    params: this.friendParams,
                    ...this.headersConfig
                }).then(result=>{
                    this.loadText = '加载更多';
                    this.isloadFinsh = false;
                    var res = result.data;
                    if(res.code == 200){
                        if(type == 'reset'){
                            this.friendList = res.data.records
                        }else{
                            this.friendList = this.friendList.concat(res.data.records);
                        }
                        if(this.friendList.length == res.data.total){
                            this.loadText = '已全部加载'
                        }
                    }else{
                        this.friendParams.pageNum -= number;
                        this.$toast(res.msg)
                    }
                    
                }).catch(err=>{
                    this.loadText = '加载更多';
                    this.isloadFinsh = false;
                    if(number > 0){
                        this.$toast(err.message);
                    }
                    this.friendParams.pageNum -= number;
                })
            }
            
        },
        /**
         * 生成二维码
         */
        async getQRcode(){
            // 获取邀请码
            await this.getInvitedCode();

            // 二维码生成需要编码
            var content = encodeURIComponent(location.origin + `/landing/invited?iCode=${this.invitedCode}&um_chnnl=share&um_from_appkey=${this.appKey}`)
            this.$api.invitedPageQRcode({
                content: content
            }).then(result=>{
                var res = result.data;
                if(res.code == 200){
                    this.qrCode = res.data
                }
            }).catch(err=>{})
        },
        /**
         * 获取邀请码
         */
        async getInvitedCode(){
            var res = await this.$api.invitedNewUserCode({
                ...this.headersConfig
            })
            if(res.data.code == 200){
                this.invitedCode = res.data.data
            }
        },
        
        /**
         * 邀请人数控制函数
         */
        async isOutRange(){
            var result = await this.$api.todayInvitedNumber({
                ...this.headersConfig
            })
            var res = result.data;
            if(res.code == 200){
                this.personNumber = res.data;
            }else{
                this.$toast(res.msg)
            }
            var flag = true;
            if ( this.personNumber >= 5 ) {
                flag = false;
            }
            return flag;
        },
        isOutTime(){
            if(new Date().getTime() >= 1670137851000){
                return false
            }else{
                return true
            }
        },
        /**
         * 显示邀请弹框函数
         * type   String   粘贴类型：提醒/邀请   用来判断设置粘贴内容
         */
        async showInvited(type){
            if(type == '邀请'){
                if (!this.urlParams.token) {
                  this.loginShow = true;
                  return
                }
                window._hmt.push(['_trackEvent', '拉新页面', '点击', '邀请按钮']) // 事件跟踪
                // 活动已结束
                if(!this.isOutTime()){
                    this.$toast('活动已结束');
                    return
                }
                // 当天邀请人数超过5人
                var bool = await this.isOutRange();
                if(!bool){
                    this.$toast('每天最多可邀请5个朋友哦！')
                    return
                }
            }
            this.invitedShow = true;
            this.invitationType = type;
        },
        /**
         * 显示面对面分享函数
         */
        async showShare(){
            // 活动已结束
            if(!this.isOutTime()){
                this.$toast('活动已结束');
                return
            }
            // 当天邀请人数超过5人
            var bool = await this.isOutRange();
            if(!bool){
                this.$toast('每天最多可邀请5个朋友哦！')
                return
            }
            // 没有二维码图片的时候需要重新请求接口生成
            if(this.qrCode === ''){
                this.getQRcode();
            }
            this.shareShow = true
        },
        /**
         * 关闭弹框
         * alertName    String    指定关闭的是哪个弹框
         * shareShow    面对面分享弹框
         * invitedShow  邀请弹框
         */
        closeComfirm(alertName){
            this[alertName] = false;
        },
        /**
         * 随机生成指定空格，用来加入粘贴内容中
         * 手机上如果是相同的粘贴内容，第二次及以后不会出现在键盘上方
         */
        random(a,b){
            var randomNum = Math.round(Math.random() * (b - a) + a);
            return randomNum;
        },
        /**
         * 粘贴函数
         */
        toPaste(){
            var value = this.invitationType == '邀请' ? '帮我点一下，咱俩都有钱，当天就能提现！' : 'Hi，帮个忙，登录凯迪网，咱俩都有钱拿！';

            // 随机生成1-6个空格
            var space = ' ';
            space = space.repeat(this.random(1,6));

            // 创建一个input元素来设置粘贴内容
            var input = document.createElement('input');
            input.setAttribute('readonly', 'readonly');     // 防止调起键盘面板

            var url = this.invitationType == '邀请' 
            ? (location.origin + `/landing/invited?iCode=${this.invitedCode}&um_chnnl=share&um_from_appkey=${this.appKey}` + space) 
            : location.origin + `/?oCode=${Math.random().toString().slice(12)}`

            input.setAttribute('value', value + url);
            document.body.appendChild(input);

            // 选中输入框内容
            input.select();

            // 复制，如果ios不兼容，可以加这行代码试试    
            input.setSelectionRange(0, 9999);

            if (document.execCommand('copy')) {
                document.execCommand('copy');
            }
            document.body.removeChild(input);

            // 收起粘贴弹窗
            this.invitedShow = false;

            // 判断是安卓或者ios，唤醒唤醒微信面板，需要跟移动端同学对接
            if(this.isAndroid){
                window.app.pasteShareMsg('');
            }else if(this.isIos){
                window.webkit.messageHandlers.pasteShareMsg.postMessage('');
            }
        },
        /**
         * 跳转路由
         * type     Number
         * 0        待到账
         * 1        已到账
         * money    Number  钱
         */
        ToAccount(type,money){
            if (!this.urlParams.token) {
              this.loginShow = true;
              return;
            }
            if(money <= 0){
                this.$toast('暂无数据哦');
                return
            }
            this.$router.push({
                path: '/landing/account',
                query:{
                    type,
                    token: this.urlParams.token
                }
            })
        },
        /**
         * 领取奖励函数
         */
        receivedReward(data){
            window._hmt.push(['_trackEvent', '拉新页面', '点击', '领取奖励']) // 事件跟踪
            if(data.price == -1){
                this.$toast('奖励已失效');
                return
            }
            if(data.price == 0 && data.proStatus == 3){
                this.$toast('暂无奖励');
                return
            }
            if(data.price == 0){
                this.$toast('奖励已领取');
                return
            }
            this.$api.receiveTaskReward({
                params: {
                    userId:data.uid
                },
                ...this.headersConfig
            }).then(result=>{
                var res = result.data;
                if(res.code == 200){
                    data.price = 0;
                    this.$toast('领取成功');
                    data.checkDay = data.sort;
                    if(data.sort < 7 && data.sort > 2){
                        data.sort = 3
                    }
                    // 更新待到账金额
                    this.getOldAllAccount();
                    // 更新列表页
                    this.friendParams.number = 1;
                    this.getFriendList(0,'reset')
                }else{
                    this.$toast(res.msg)
                }
            }).catch(err=>{
                this.$toast(err.message)
            })
            
        },
        /**
         * 滚动函数
         */
        scrollHandler(){
            // 显示隐藏底部的邀请按钮
            if(this.$refs.invitedButton){
                var rect = this.$refs.invitedButton.getBoundingClientRect();
                var oH = this.$refs.invitedButton.offsetHeight;
                if(oH + rect.top < 0){
                    this.bottomInvitedShow = true;
                }else{
                    this.bottomInvitedShow = false;
                }
            }
        },
        /**
         * 某一天的最后毫秒数
         * day: 第几天
         */
        dateLastMinute(day){
            return (new Date(new Date().toLocaleDateString()).getTime() + 24 * day * 60 * 60 * 1000 - 1)
        },
        /**
         * 计算按剩余时间
         * second   Number   时间,单位为秒
         */
        countDown(second) {
            var invalidTime = ''
            var d = parseInt(second / 60 / 60 / 24); // 天
            var h = parseInt(second / 60 / 60 % 24).toString().padStart(2, '0'); //时
            var m = parseInt(second / 60 % 60).toString().padStart(2, '0');; // 分
            var s = parseInt(second % 60).toString().padStart(2, '0');; // 当前的秒
            if(d >= 1){
                invalidTime = d + '日' + h + ':' + m + ':' + s;
            }else{
                invalidTime = h + ':' + m + ':' + s;
            }
            return invalidTime
        },
        /**
         * 定时器，实时计算任务领取奖励剩余时间
         */
        interval(){
            this.invalidTimer = setInterval(() => {
                for (var item of this.friendList) {
                    var key = 'rewardTime';
                    if(item.proStatus == 0){
                        key = 'time'
                    }
                    item[key] -= 1;
                    if(item[key] < 0){
                        if(item.price != 0 && item.price != -2){
                            item.price = -1;
                        }
                    }else{
                        var invalidTime = this.countDown(item[key]);
                        this.$set(item, 'invalidTime', invalidTime);
                    }
                }
            }, 1000);
        },
        toAppLogin(){
          this.loginShow = false;
          // 判断是安卓或者ios，唤醒唤醒微信面板，需要跟移动端同学对接
          if(this.isAndroid){
              window.app.newUserFissionLogin('');
          }else if(this.isIos){
              window.webkit.messageHandlers.newUserFissionLogin.postMessage('');
          }
        }
    },
}
</script>

<style lang="less">
    .user-fission{
        background: url(https://cdn.9kd.com/kdnet/user-fission/user_fission_bg.png) top center no-repeat #FEAD62;
        background-size: 100%;
        font-size: 14px;
        .van-overlay{
            background: rgba(0,0,0,0.5);
        }
    }
    .main {
        padding: 0 15px;
    }
    .user-uninvited-content{
        padding: 0 15px 75px 15px;
        .user-uninvited-bg{
            position: relative;
            margin-top: 30px;
            padding: 35px 20px 15px 20px;
            background: #FFFFFF;
            border-radius: 15px;
            box-shadow: inset 0px 0px 19px 8px #FFDDC5;
        }
    }
    .user-title{
        padding: 20px 0 18px 0;
        img{
            display: block;
            width: 79%;
            margin: 0 auto;
        }
    }
    .user-contet{
        position: relative;
        margin: 0 auto;
        .user-contet-bg{
            display: block;
            width: 100%;
        }
        .user-content-tips{
            position: absolute;
            left: 0px;
            top: 17%;
            width: 100%;
            text-align: center;
            font-size: 15px;
            color: #FA4850;
        }
        .user-content-money{
            position: absolute;
            left: calc(50% - 15%);
            top: 27%;
            width: 22%;
            img{
                width: 100%;
            }
        }
        .user-content-get-text{
            position: absolute;
            left: 60%;
            top: 31%;
            padding: 4px 2px 5px 2px;
            border-radius: 15px;
            background: linear-gradient(#F47056, #F11B1F);
            font-size: 11px;
            color: white;
            font-weight: bold;
            writing-mode: vertical-lr;
        }
        .user-content-invite-btn{
            position: absolute;
            left: calc(50% - 34%);
            bottom: 21%;
            box-sizing: border-box;
            width: 68%;
            padding: 10px;
            border-radius: 50px;
            background: linear-gradient(#FFF0BD, #FC9C53, #FBA457);
            box-shadow: 0px 1.5px 8px 0px #b31b25, 0px 1.5px 3px 0px rgba(255, 255, 255, 0.64);
            animation: breathing .4s ease infinite alternate;
            p{
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                font-size: 22px;
                text-align: center;
                img{
                    height: 22px;
                    margin-right: 5px;
                }
                .user-content-invite-ljyq[data-text]{
                    position: relative;
                    color: #F11B1F;
                }
                .user-content-invite-ljyq[data-text]::after{
                    content: attr(data-text);  
                    display:block;
                    position: absolute;  
                    left: 0;  
                    top: 0;
                    z-index: 2;
                    color: #F47056;
                    -webkit-mask-image: -webkit-gradient(linear, 0 0, 0 60%, from(#F47056), to(rgba(0, 0, 255, 0)));
                }
            }
        }
        .user-content-type{
            display: flex;
            justify-content:space-around;
            align-items: center;
            position: absolute;
            bottom: 11%;
            left: 0;
            width: 68%;
            padding: 0 16%;
            color: white;
            font-size: 14px;
            p{
                display: flex;
                align-items: center;
                height: 25px;
                span{
                    margin-right: 4px;
                }
            }
        }
        .user-content-time{
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            text-align: center;
            font-size: 11px;
            color: #FA4850;
        }
    }
    .user-account{
        display: flex;
        .user-account-btn{
            flex: 1;
            text-align: center;
            .user-account-title{
                font-size: 14px;
                color: #666666;
            }
            .user-account-money{
                padding: 10px 0;
                color: #E52218;
                font-size: 18px;
                span{
                    font-size: 24px;
                    font-weight: 500;
                }
            }
        }
    }
    .invited-same-title{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0px;
        left: 50%;
        width: 57%;
        height: 32px;
        background: url(https://cdn.9kd.com/kdnet/user-fission/user_fission_title_bg.png) center no-repeat;
        background-size: 100% 100%;
        color: white;
        font-weight: bold;
        font-size: 16px;
        transform: translate(-50%, -50%);
    }
    .invited-playing-type{
        display: flex;
        justify-content: space-between;
        padding: 0 0 10px 0;
        font-size: 12px;
        text-align: center;
        .invited-playing-step{
            width: 29%;
            text-align: center;
            .invited-playing-img{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 60px;
                height: 60px;
                margin: auto;
                border-radius: 50%;
                background: linear-gradient(0deg, #FA3638, #FB706B, #FB706B);
            }
            img{
                display: block;
                width: 60%;
                margin: auto;
            }
            p{
                font-size: 14px;
                margin-top: 10px;
                font-weight: 500;
            }
        }
        .invited-playing-arrow{
            width: 6.5%;
            img{
                width: 100%;
                margin-top: 18px;
            }
        }
    }
    .invited-win-rule{
        .invited-rule-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            padding: 15px 10px;
            margin-bottom: 13px;
            border-radius: 4px;
            background: #FFECE5;
            font-size: 12px;
            .invited-rule-left{
                display: flex;
                align-items: center;
                .invited-rule-icon{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 40px;
                    background: #F2514B;
                    border-radius: 50%;
                    .iconfont{
                        font-size: 22px;
                        color: white;
                    }
                }
                .invited-rule-text{
                    margin-left: 10px;
                    .invited-rule-t-big{
                        height: 24px;
                        font-size: 15px;
                        color: #1B0706;
                        font-weight: bold;
                    }
                    .invited-rule-t-small{
                       color: #67403D; 
                       font-size: 12px;
                    }
                }
            }
            .invited-rule-money{
                img{
                    display: block;
                    width: 45px;
                }
                
            }
            &:nth-child(2){
                .invited-rule-icon{
                    .iconfont{
                        font-size: 20px;
                    }
                }
            }
        }
    }
    .invited-explain{
        padding-bottom: 10px;
        font-size: 11px;
        color: #999999;
    }
    .invited-strategy{
        overflow-y: auto;
        padding: 5px;
        max-height: 70vh;
        div{
            display: flex;
            margin-bottom: 10px;
            p{
                margin-left: 10px;
                font-size: 13px;
                color: #666666;
                line-height: 1.5;
                font-weight: 500;
                &:nth-child(2){
                    flex: 1;
                }
            }
            p.num{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 32px;
                height: 32px;
                margin: -7px -10px;
                background: linear-gradient(0deg, #FA3638, #FB706B, #FB706B);
                border-radius: 50%;
                color: white;
                font-size: 24px;
                text-align: center;
                transform: scale(0.5);
            }
        }
    }
    .user-friend-load-more{
        margin: 20px 0;
        text-align: center;
        font-size: 13px;
        color: #ccc;
    }
    .invited-fixed-rule{
        position: fixed;
        top: 7%;
        right: 0;
        padding: 5px;
        border-radius: 5px 0 0 5px;
        background: rgba(226,46,52,0.8);
        color: rgba(255,255,255,0.8);
        font-size: 13px;
        writing-mode: vertical-lr;
        letter-spacing: 1px;
    }
    .invited-fixed-btn{
        display: block;
        position: fixed;
        bottom: 0;
        left: 15%;
        box-sizing: border-box;
        width: 70%;
        margin: 10px auto;
        padding: 10px;
        background: linear-gradient(#FFF0BD, #FC9C53, #FBA457);
        box-shadow: 0px 1.5px 8px 0px #b31b25, 0px 1.5px 3px 0px rgba(255, 255, 255, 0.64);
        border-radius: 50px;
        font-size: 16px;
        text-align: center;
        animation: breathing .4s ease infinite alternate;
        p{
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            font-size: 22px;
            text-align: center;
            img{
                height: 22px;
                margin-right: 5px;
            }
            .user-content-invite-ljyq[data-text]{
                position: relative;
                color: #F11B1F;
            }
            .user-content-invite-ljyq[data-text]::after{
                display:block;
                content: attr(data-text);  
                color: #F47056;
                position: absolute;  
                left: 0;  
                top: 0;
                z-index: 2;
                -webkit-mask-image: -webkit-gradient(linear, 0 0, 0 60%, from(#F47056), to(rgba(0, 0, 255, 0)));
            }
        }
    }
    .invited-comfirm{
        position: relative;
        padding: 40px 20px 40px;
        .comfirm-close-btn{
            position: absolute;
            bottom: -60px;
            left: 50%;
            z-index: 2;
            width: 30px;
            height: 30px;
            border: 2px solid #dedede;
            border-radius: 50%;
            line-height: 30px;
            color: #dedede;
            text-align: center;
            transform: translate(-50%, 0);
            .iconfont{
                font-size: 12px;
            }
        }
        .comfirm-text{
            padding-left: 30px;
            p{
                margin-bottom: 22px;
            }
        }
        .comfirm-guide-img{
            box-sizing: border-box;
            margin: 0 0 15px 0;
            border: 1px dashed #999;
            img{
                display: block;
                width: 100%;
            }
        }
        .comfirm-paste-btn{
            text-align: center;
            button{
                width: 90%;
                padding: 12px 0;
                border: none;
                outline: none;
                background: linear-gradient(0deg, #FA3638, #FB706B, #FB706B);
                border-radius: 50px;
                color: white;
                font-size: 18px;
                font-weight: 500;
            }
        }
    }
    .small-padding{
        padding: 30px 15px 15px;
    }
    .no-hidden-comfirm{
        overflow: initial;
    }
    .share-comfirm{
        position: relative;
        text-align: center;
        .share-comfirm-bg{
            img{
                display: block;
                width: 100%;
            }
        }
        .comfirm-qrcode{
            position: absolute;
            left: 50%;
            top: 50%;
            width: 39%;
            transform: translate(-50%, -50%);
            img{
                width: 100%;
            }
        }
        .share-comfirm-close-btn{
            position: absolute;
            bottom: -60px;
            left: 50%;
            z-index: 2;
            width: 30px;
            height: 30px;
            border: 2px solid #dedede;
            border-radius: 50%;
            line-height: 30px;
            color: #dedede;
            transform: translate(-50%, 0);
            .iconfont{
                font-size: 12px;
            }
        }
    }
    .van-dialog{
        border-radius: 7px;
    }
    .button-group{
      display: flex;
      border-top: 1px solid #f4f4f5;
       .btn{
        padding: 15px;
        flex: 1;
        text-align: center;
      }
      .red {
        border-left: 1px solid #f4f4f5;
        color: #F7321C;
      }
    }
    .login-tips{
      padding: 50px 0;
      text-align: center;
    }
</style>
<style>
@keyframes breathing
{
    from {
        transform: scale(0.9);
    }
    to {
        transform: scale(1);
    }
}
.pc-app .invited-fixed-btn{
  width: 400px;
  left: calc(50% - 200px);
}
.pc-app .invited-fixed-rule {
  right: calc(50% - 220px);
}
</style>